<template>
    <v-select
        :label="label"
        dense
        outlined
        :value="value"
        :rules="rules"
        :items="radios"
        @change="v => $emit('input', v)"
    />
</template>

<script>
import compositeFieldMixin from '@/widgets/mixins/compositeFieldMixin'

export default {
    name: 'WGenderInput',
    mixins: [ compositeFieldMixin ],
    data() {
        return {
            radioGroup: null
        }
    },
    computed: {
        label() {
            const labels = {
                en: 'Gender',
                ru: 'Пол',
                uk: 'Стать'
            }

            return labels[this.locale]
        },
        radios() {
            const radios = {
                en: [
                    {
                        text: 'Male',
                        value: 'male'
                    },
                    {
                        text: 'Female',
                        value: 'female'
                    }
                ],
                ru: [
                    {
                        text: 'Мужской',
                        value: 'male'
                    },
                    {
                        text: 'Женский',
                        value: 'female'
                    }
                ],
                uk: [
                    {
                        text: 'Чоловіча',
                        value: 'male'
                    },
                    {
                        text: 'Жіноча',
                        value: 'female'
                    }
                ]
            }

            return radios[this.locale]
        },
        requiredMessage() {
            const messages = {
                en: 'This field is required',
                ru: 'Поле обязательно для заполнения',
                uk: 'Поле обов\'язкове для заповнення'
            }

            return messages[this.locale]
        },
        rules() {
            if (!this.required) {
                return []
            }

            return [
                value => {
                    if (value && value.length) {
                        return true
                    } else {
                        return this.requiredMessage
                    }
                }
            ]
        },
    }
}
</script>

<style lang=scss>
    .w-gender-group {
        .v-input--selection-controls {
            margin-top: 0;
        }
    }

</style>
