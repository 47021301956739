<template>
    <v-card
        v-if="!mini"
        v-bind="vCardProps"
    >
        <div class="d-flex">
            <div v-if="icon" class="pt-4 pl-4 mr-4">
                <w-icon color="primary" :value="icon" />
            </div>
            <div>
                <div
                    v-if="overline"
                    class="caption text-truncate text-overline mb-2"
                    style="line-height: inherit"
                >
                    {{ overline }}
                </div>
                <v-card-title>{{ title }}</v-card-title>
                <v-card-text v-if="hasNote">
                    <slot name="note">{{ note }}</slot>
                </v-card-text>
            </div>
        </div>
        <div class="mt-auto">
            <v-divider></v-divider>
            <slot name="actions">
                <div class="pa-4 text-right">
                    <w-icon color="primary" value="NEXT_DOUBLE" />
                </div>
            </slot>
        </div>
    </v-card>
    <v-card
        v-else
        v-bind="vCardProps"
    >
        <v-card-title
            class="d-flex align-start"
        >
            <w-icon v-if="icon" color="primary" :value="icon" class="mr-4 mt-1"/>
            <div class="text-truncate" style="flex: 10">
                <div
                    v-if="overline"
                    class="caption text-truncate text-overline mb-2"
                    style="line-height: inherit"
                >
                    {{ overline }}
                </div>
                <div class="text-truncate">
                    {{ title }}
                </div>
                <div
                    v-if="hasNote"
                    class="caption text-truncate mt-1"
                >
                    <slot name="note">{{ note }}</slot>
                </div>
                <div>
                    <slot name="footer"></slot>
                </div>
            </div>
            <v-spacer></v-spacer>
            <slot name="actions">
                <div v-if="to" class="text-right">
                    <w-icon color="primary" value="NEXT_DOUBLE" style="flex: 1"/>
                </div>
            </slot>
        </v-card-title>
    </v-card>
</template>

<script>
import wEntryPreviewOptionsMixin from './mixins/wEntryPreviewOptionsMixin'

export default {
    name: 'WEntryPreviewNormal',
    mixins: [ wEntryPreviewOptionsMixin ]
}
</script>

<style lang=scss>

</style>
