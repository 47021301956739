export default {
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown
        },
        xsOnly() {
            return this.$vuetify.breakpoint.xsOnly
        }
    }
}
