<template>
    <div
        v-bind="tableBindings"
    >
        <div v-bind="loadingBindings">
            <v-progress-linear
                color="primary"
                indeterminate
            />
        </div>
        <div v-if="!hideDefaultHeader" class="fx-head">
            <slot
                name="header"
                :headers="headers"
            >
                <div
                    v-if="draggable"
                    key="th-draggable"
                    class="fx-th fx-th--draggable"
                >
                </div>
                <div
                    v-if="selectable"
                    key="th-selectable"
                    class="fx-th fx-th--selectable"
                >
                    <v-simple-checkbox
                        v-if="selectable && !singleSelect"
                        :disabled="!!excludeSelect"
                        v-model="selectState.value"
                        :indeterminate="selectState.indeterminate"
                        class="pa-0 ma-0"
                        @input="onSelectState"
                    />
                </div>
                <div
                    v-for="(head) in headers"
                    :key="`fx-head-${head.value}`"
                    class="fx-th"
                    :class="head.class"
                    :style=cellStyles(head)
                >
                    <slot
                        :name="`head.${head.value}`"
                        :head="head"
                    >
                        <w-fx-table-header-item
                            :head="head"
                            @click="onOrderBy"
                        />
                    </slot>
                </div>
            </slot>
        </div>
        <div class="fx-body">
            <template v-if="computedItems.length === 0">
                <div class="fx-row">
                    <div class="fx-col align-center justify-center">
                        <slot name="empty">{{ emptyText }}</slot>
                    </div>
                </div>
            </template>
            <div
                v-for="(item, i) in computedItems"
                :key="item[itemKey]"
                class="fx-row"
                v-ripple="hasClickRowListener"
                @click="$emit('click:row', item)"
            >
                <div
                    v-if="draggable"
                    :key="'col-draggable' + i"
                    class="fx-col fx-col--draggable draggable-handle"
                >
                    <w-icon value="DRAGGABLE"/>
                </div>
                <div
                    v-if="selectable"
                    :key="'col-selectable' + i"
                    class="fx-col fx-col--selectable"
                >
                    <v-simple-checkbox
                        :disabled="excludeSelect && excludeSelect(item)"
                        :key="item[itemKey]"
                        :value="isItemSelected(item)"
                        :on-icon="singleSelect ? '$radioOn' : '$checkboxOn'"
                        :off-icon="singleSelect ? '$radioOff' : '$checkboxOff'"
                        :color="isItemSelected(item) ? 'primary' : null"
                        class="pa-0 ma-0"
                        @input="state => onItemSelect(state, item)"
                    />
                </div>
                <div
                    v-for="(head, j) in headers"
                    :key="`fx-col-${i}-${j}`"
                    class="fx-col"
                    :class="[itemClass, head.itemClass]"
                    :style=cellStyles(head)
                >
                    <slot
                        :name="`item.${head.value}`"
                        :head="head"
                        :item="item"
                        :index="i"
                    >
                        {{ item[head.value] }}
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Sortable from 'sortablejs'
import _ from 'lodash'
import WFxTableHeaderItem from '@/widgets/components/WFxTable/WFxTableHeaderItem'

export default {
    name: 'WFxTable',
    components: { WFxTableHeaderItem },
    props: {
        value: {
            type: Array,
            default: function () {
                return []
            }
        },
        loading: {
            type: Boolean,
            default: false
        },
        headers: {
            type: Array,
            default: function () {
                return []
            }
        },
        items: {
            type: Array,
            default: function () {
                return []
            }
        },
        itemClass: {
            type: String,
            default: undefined
        },
        emptyText: {
            type: String,
            default: 'No data'
        },
        hideDefaultHeader: {
            type: Boolean,
            default: false
        },
        hideDivider: {
            type: Boolean,
            default: false
        },
        bordered: {
            type: Boolean,
            default: false
        },
        rowHover: {
            type: Boolean,
            default: false
        },
        colHover: {
            type: Boolean,
            default: false
        },
        singleSelect: {
            type: Boolean,
            default: false
        },
        selectable: {
            type: Boolean,
            default: false
        },
        draggable: {
            type: Boolean,
            default: false
        },
        draggableKey: {
            type: String,
            default: 'weight'
        },
        itemKey: {
            type: String,
            default: 'id'
        },
        excludeSelect: {
            type: Function,
            default: null
        }
    },
    computed: {
        tableBindings() {
            const classes = [ 'fx-table' ]

            if(this.bordered) {
                classes.push('fx-table--' + 'bordered')
            }

            if(this.rowHover) {
                classes.push('fx-table--' + 'row-hover')
            }

            if(this.hasClickRowListener) {
                classes.push('fx-table--' + 'row-clicked')
            }

            if(this.colHover) {
                classes.push('fx-table--' + 'col-hover')
            }

            if(this.hideDivider) {
                classes.push('fx-table--' + 'hide-divider')
            }

            return {
                id: this.tableId,
                class: classes
            }
        },
        loadingBindings() {
            const bindings = {
                class: ['fx-loading']
            }

            if(this.loading) {
                bindings.class.push('fx-loading--loading')
            }

            return bindings
        },
        computedItems() {
            const itemKey = this.itemKey
            const draggableKey = this.draggableKey
            let tableItems = this.items

            tableItems = tableItems.map((o, i) => {
                if(o.__defaultIndex === undefined) {
                    o.__defaultIndex = i
                }

                if(this.draggable) {
                    if(!o[itemKey]) {
                        throw new Error(`Cannot access to ${ itemKey }`)
                    }

                    if(!o[draggableKey]) {
                        o[draggableKey] = i.toString()
                    }
                }

                return o
            })

            const orderBy = this.orderBy
            const orderDirection = this.orderDirection

            if(orderBy) {
                const iterates = orderDirection
                    ? [ orderBy ]
                    : [ '__defaultIndex' ]

                const orders = orderDirection
                    ? [ orderDirection ]
                    : [ 'asc' ]

                tableItems = _.orderBy(tableItems, iterates, orders)
            }

            return tableItems
        },
        computedSelectedItems() {
            return [].concat(this.value)
        },
        hasClickRowListener() {
            return !!this.$listeners['click:row']
        }
    },
    watch: {
        items() {
            this.setSort()
            this._defineSelectState()
        }
    },
    data() {
        return {
            tableId: 'w-fx-table-' + _.random(100, 1000),
            orderBy: null,
            orderDirection: null,
            selectState: {
                value: false,
                indeterminate: false
            }
        }
    },
    mounted() {
        this.setSort()
        this._defineSelectState()
    },
    methods: {
        setSort() {
            if(!this.draggable) {
                return
            }

            const el = document.querySelector(`#${ this.tableId } .fx-body`)

            if(!el) {
                return
            }

            this.sortable = Sortable.create(el, {
                animation: 150,
                sort: true,
                ghostClass: 'draggable-ghost', // Class name for the drop placeholder,
                handle: '.draggable-handle',
                setData: function (dataTransfer) {
                    dataTransfer.setData('Text', '')
                    // to avoid Firefox bug
                    // Detail see : https://github.com/RubaXa/Sortable/issues/1012
                },
                onEnd: evt => {
                    this.$nextTick(() => {
                        this.sortableItems(evt)
                    })
                }
            })
        },
        sortableItems(evt) {
            const draggableKey = this.draggableKey
            const tableItems = [].concat(this.computedItems)
            const targetRow = tableItems.splice(evt.oldIndex, 1)[0]

            tableItems.splice(evt.newIndex, 0, targetRow)

            for (const index in tableItems) {
                tableItems[index][draggableKey] = index
            }

            // this.tableItems = tableItems

            this.$emit('dragged', tableItems)
            this.$emit('update:items', tableItems)
        },
        cellStyles(head) {
            const styles = {}

            if(head.width) {
                styles.maxWidth = `${ head.width }px`
            }

            return styles
        },
        isItemSelected(item) {
            return this.computedSelectedItems.includes(item[this.itemKey])
        },
        _defineSelectState() {
            const tableItems = this.computedItems
            const selected = this.computedSelectedItems

            if(tableItems.length === selected.length) {
                this.selectState.value = true
                this.selectState.indeterminate = false
            } else if(selected.length > 0 && selected.length < tableItems.length) {
                this.selectState.value = false
                this.selectState.indeterminate = true
            } else {
                this.selectState.value = false
                this.selectState.indeterminate = false
            }
        },
        onOrderBy(field, direction) {
            this.orderBy = field
            this.orderDirection = direction
        },
        onSelectState(state) {
            let selectedItems = this.computedSelectedItems

            if(state) {
                this.computedItems.forEach(o => {
                    if(!selectedItems.includes(o[this.itemKey])) {
                        selectedItems.push(o[this.itemKey])
                    }
                })
            } else {
                selectedItems = []
            }

            this.selectState.indeterminate = false

            this.emitInput(selectedItems)
        },
        onItemSelect(state, item) {
            if(!item[this.itemKey]) {
                throw new Error(`Cannot access to ${ this.itemKey }`)
            }

            // Define selected items sate
            const itemId = item[this.itemKey]
            let selected = this.computedSelectedItems

            if(state) {
                if(this.singleSelect) {
                    selected = [ itemId ]
                } else {
                    selected.push(itemId)
                }
            } else {
                if(this.singleSelect) {
                    selected = []
                } else {
                    selected = selected.filter(o => o !== itemId)
                }
            }

            this.emitInput(selected)

            // Define select state
            this._defineSelectState()
        },
        emitInput(selectedItems) {
            this.$emit('input', selectedItems)
        }
    }
}
</script>

<style lang=scss>
.fx-table {
    $td-draggable-size: 24px;
    $td-selectable-size: 56px;

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 0;
    position: relative;

    .fx-th, .fx-col {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1 0 20px;
        padding: 0 16px;
    }

    .fx-head {
        display: flex;

        .fx-th {
            font-weight: 600;
            font-size: 0.75rem;
            min-height: 48px;
            text-transform: uppercase;
            display: flex;
            align-items: center;

            &.text-center {
                justify-content: center;
            }
        }
    }

    .fx-loading {
        height: 4px;
        opacity: 0;
        transition: opacity .2s;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;

        &--loading {
            opacity: 1;
        }
    }

    .fx-body {
        display: flex;
        flex-direction: column;
        min-height: 0;

        .fx-row {
            display: flex;
            transition: all .3s;

            .fx-col {
                display: flex;
                align-items: center;
                font-size: 0.875rem;
                min-height: 3.125rem;
                transition: all .3s;
                position: relative;

                &--draggable {
                    cursor: grab;
                }
            }
        }
    }

    .fx-col--draggable,
    .fx-th--draggable {
        max-width: $td-draggable-size;
        padding: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        //border-bottom: none !important;
        //border-top: none !important;
        //background: transparent !important;
    }

    .fx-col--selectable,
    .fx-th--selectable {
        max-width: $td-selectable-size;
        padding: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        //border-bottom: none !important;
        //border-top: none !important;
        //background: transparent !important;

        .v-input--selection-controls__input {
            margin-right: 0 !important;
        }
    }

    .draggable-ghost {
        background-color: #efedfd;
    }

    &--hide-divider {
        .fx-col {
            border-bottom: none !important;
        }
    }
}

$theme-light-bcolor: rgba(94, 86, 105, .14);
$theme-dark-bcolor: rgba(231, 227, 252, .14);

.theme--light {
    .fx-table {
        &--row-hover {
            .fx-row {
                &:hover {
                    background: lighten($theme-light-bcolor, 30%);
                }
            }
        }

        &--row-clicked {
            .fx-row {
                &:hover {
                    cursor: pointer;
                }
            }
        }

        &--col-hover {
            .fx-col {
                &:hover {
                    background: lighten($theme-light-bcolor, 30%);
                }
            }
        }

        .fx-th {
            background-color: #5e56690a;
            border-bottom: thin solid $theme-light-bcolor;
            border-top: thin solid $theme-light-bcolor;
        }

        .fx-col {
            border-bottom: thin solid $theme-light-bcolor;
        }

        &--bordered {
            .fx-col + .fx-col {
                border-left: thin solid $theme-light-bcolor;
            }
        }
    }
}

.theme--dark {
    .fx-table {
        &--row-hover {
            .fx-row {
                &:hover {
                    background: darken($theme-dark-bcolor, 90%);
                }
            }
        }

        &--col-hover {
            .fx-col {
                &:hover {
                    background: darken($theme-dark-bcolor, 90%);
                }
            }
        }

        .fx-th {
            background-color: #312d4b;
            border-bottom: thin solid $theme-dark-bcolor;
            border-top: thin solid $theme-dark-bcolor;
        }

        .fx-col {
            border-bottom: thin solid $theme-dark-bcolor;
        }

        &--bordered {
            .fx-col + .fx-col {
                border-left: thin solid $theme-dark-bcolor;
            }
        }
    }
}

</style>
