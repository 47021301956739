<template>
    <div class="w-simple-form-preview ">
        <template
            v-for="(field, index) in fields"
        >
            <v-divider
                v-if="index > 0"
                :key="index"
            />
            <v-row
                :key="field.name"
                class="my-3"
                no-gutters
            >
                <v-col
                    cols="12"
                    sm="3"
                    class="text-uppercase caption mb-sm-0 mb-2"
                >
                    {{ field.props.label }}:
                </v-col>
                <v-col
                    cols="12"
                    sm="9"
                    class="text-sm"
                >
                    <slot
                        :name="`preview.${field.name}`"
                        :item="item"
                    >
                        {{ item[field.name] }}
                    </slot>
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
export default {
    name: 'WSimpleFormPreview',
    props: {
        title: {
            type: String,
            default: null
        },
        subtitle: {
            type: String,
            default: null
        },
        item: {
            type: [ Object, Function ],
            default: function () {
                return {}
            }
        },
        fields: {
            type: Array,
            default: function () {
                return []
            }
        }
    }
}
</script>

<style lang=scss>
    .w-simple-form-preview {
        &__label {
            min-width: 100px;
        }
    }
</style>
