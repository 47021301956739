<template>
    <div v-if="title || subtitle" class="d-flex align-start mb-3">
        <div>
            <h3 class="text-base font-weight-semibold">{{ title }}</h3>
            <div class="caption">{{ subtitle }}</div>
        </div>
        <v-spacer/>
        <v-btn
            v-if="action"
            icon
            color="primary"
            @click="$emit(action)"
        >
            <w-icon :value="action.toUpperCase()"/>
        </v-btn>
    </div>
</template>

<script>
export default {
    name: 'WSimpleFormHeader',
    props: {
        title: {
            type: String,
            default: null
        },
        subtitle: {
            type: String,
            default: null
        },
        action: {
            type: String,
            default: null
        }
    }
}
</script>

<style lang=scss>

</style>
