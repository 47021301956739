<template>
    <div class="d-flex align-start">
        <slot name="icon">
            <w-icon
                size="20"
                :value="icon"
                style="margin-top: 2px"
            />
        </slot>
        <div class="ms-2">
            <slot name="title">
                <p class="text--primary mb-0">
                    {{ title }}
                </p>
            </slot>
            <span class="text-xs">
                <slot name="subtitle">
                    {{ subtitle }}
                </slot>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WOptionsListItem',
    props: {
        icon: {
            type: String,
            default: 'mdi-record-circle-outline'
        },
        title: {
            type: String,
            default: null
        },
        subtitle: {
            type: String,
            default: undefined
        }

    }
}
</script>

<style lang="scss">

</style>
