/* eslint-disable */
const Debugger = Object.freeze({
    debug: process.env.VUE_APP_DEBUG === 'true',

    /**
	 * @param {*} data
	 */
    log: (...data) => {
        if (Debugger.debug) console.log(...data)
    },

    /**
	 * @param {*} data
	 */
    error: (...data) => {
        if (Debugger.debug) console.error(...data)
    },

    /**
	 * @param {*} data
	 */
    warn: (...data) => {
        if (Debugger.debug) console.warn(...data)
    }
})

export default Debugger
