<template>
    <v-sheet
        v-if="isSortable"
        color="transparent"
        class="d-flex align-center cursor-pointer"
        @click="onClick"
    >
        {{ head.text }}
        <v-icon small>{{ icon }}</v-icon>
    </v-sheet>
    <span v-else>{{ head.text }}</span>
</template>

<script>
export default {
    name: 'WFxTableHeaderItem',
    props: {
        head: {
            type: Object,
            required: true
        }
    },
    computed: {
        isSortable() {
            if(!Object.keys(this.head).includes('sortable') && this.head.text) {
                return true
            }

            return !!this.head.sortable && this.head.text
        },
        icon() {
            if(!this.direction) {
                return 'mdi-menu-swap'
            }

            return this.direction === 'asc'
                ? 'mdi-menu-down'
                : 'mdi-menu-up'
        }
    },
    data() {
        return {
            direction: null
        }
    },
    methods: {
        onClick() {
            if(this.direction === null) {
                this.direction = 'asc'
            } else if(this.direction === 'asc') {
                this.direction = 'desc'
            } else if(this.direction === 'desc') {
                this.direction = null
            }

            this.$emit('click', this.head.value, this.direction)
        }
    }
}
</script>
