<template>
    <div class="w-options-list">
        <w-options-list-item
            v-for="(item, index) in items"
            :key="`WOptions-list-item-${index}`"
            :icon="item.icon"
            :title="item.title"
            :subtitle="item.subtitle"
            :class="`${index > 0 ? 'mt-2':''}`"
        />
    </div>
</template>

<script>
export default {
    name: 'WOptionsList',
    props: {
        items: {
            type: Array,
            default: function () {
                return []
            }
        }
    }
}
</script>

<style lang="scss">

</style>
