<template>
    <v-icon v-bind="iconProps">
        <slot>{{ icon }}</slot>
    </v-icon>
</template>

<script>
import icons from './icons'

export default {
    name: 'WIcon',
    props: [ 'value', 'color', 'dark', 'dense', 'disabled', 'large', 'left', 'light', 'right', 'size', 'small', 'tag', 'x-large', 'x-small' ],
    computed: {
        icons() {
            return icons
        },
        icon() {
            const icon = this.value
            return icons[icon] ? icons[icon] : icon
        },
        iconProps() {
            const props = Object.assign({}, this.$props)
            delete props.value

            return props
        }
    }

}
</script>
