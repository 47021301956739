<template>
    <div>
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    ref="birthdayText"
                    :value="value"
                    :label="label"
                    readonly
                    outlined
                    dense
                    :rules="rules"
                    v-bind="attrs"
                    v-on="on"
                >
                    <template #prepend-inner>
                        <v-icon size="20" style="margin-top: 3px">mdi-calendar</v-icon>
                    </template>
                </v-text-field>
            </template>
            <v-date-picker
                :value="value"
                :first-day-of-week="firstDayOfWeek"
                :active-picker.sync="activePicker"
                :locale="computedLocale"
                :max="max"
                :min="min"
                @change="save"
            ></v-date-picker>
        </v-menu>
    </div>
</template>

<script>
import moment from 'moment'
import compositeFieldMixin from '../../mixins/compositeFieldMixin'

export default {
    name: 'WBirthdayInput',
    mixins: [ compositeFieldMixin ],
    props: {
        firstDayOfWeek: {
            type: Number,
            default: 0
        },
        format: {
            type: String,
            default: 'YYYY-MM-DD'
        },
        minAge: {
            type: [ String, Number ],
            default: 0
        },
        maxAge: {
            type: [ String, Number ],
            default: 75
        }
    },
    computed: {
        label() {
            const labels = {
                en: 'Brthday',
                ru: 'День рождения',
                uk: 'День народження'
            }

            return labels[this.locale]
        },
        requiredMessage() {
            const messages = {
                en: 'This field is required',
                ru: 'Поле обязательно для заполнения',
                uk: 'Поле обов\'язкове для заповнення'
            }

            return messages[this.locale]
        },
        rules() {
            if (!this.required) {
                return []
            }

            return [
                value => {
                    if (value && value.length) {
                        return true
                    } else {
                        return this.requiredMessage
                    }
                }
            ]
        },
        _fieldsDefaultProps() {
            return {
                outlined: !this.outlined,
                dense: this.dense,
                rules: this.rules
            }
        },
        computedLocale() {
            const locales = {
                en: 'en-US',
                ru: 'ru-RU',
                uk: 'ru-RU'
            }

            return locales[this.locale]
        },
        max() {
            const minYear = moment().year() - Number(this.minAge)
            return `${minYear}-01-01`
        },
        min() {
            const maxYear = moment().year() - Number(this.maxAge)
            return `${maxYear}-01-01`
        }
    },
    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
    },
    data() {
        return {
            activePicker: null,
            date: null,
            menu: false,
        }
    },
    created() {
        // this.$nextTick(() => {
        //     this.date = this.value
        // })
    },
    methods: {
        save(date) {
            this.$refs.menu.save(date)
            this.$emit('input', date)
        }
    }
}
</script>

<style lang=scss>

.w-birthday-input--invalid {}

</style>
