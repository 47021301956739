export default {
    props: {
        outlined: {
            type: Boolean,
            default: false
        },
        mini: {
            type: Boolean,
            default: false
        },
        overline: {
            type: String,
            default: undefined
        },
        title: {
            type: String,
            required: true
        },
        note: {
            type: String,
            default: undefined
        },
        to: {
            type: [ String, Object ],
            default: undefined
        },
        icon: {
            type: String,
            default: undefined
        }
    },
    computed: {
        hasNote() {
            return this.note || this.$slots.note
        },
        vCardProps() {
            const props = {
                class: 'd-flex flex-column',
                height: '100%'
            }

            if (this.to) {
                props.to = this.to
                props.hover = true
                props.outlined = true
                props.loading = this.loading
            }

            if (this.outlined) {
                props.outlined = true
            }

            return props
        }
    }
}
