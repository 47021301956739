<script>
import WIcon from './index'

export default {
    name: 'WIconNext',
    extends: WIcon,
    computed: {
        icon() {
            return this.icons.NEXT
        }
    }
}

</script>
