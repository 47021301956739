/* eslint-disable */
import Vue from 'vue'
import store from '@/store'
import lprogressStore from './lprogressStore'

/**
 * Plugin of Loading progress state control
 *
 * Use this.$lprogress.isLoading() for get state
 * Use this.$lprogress.begin() for start progress
 * Use this.$lprogress.end() for end progress
 *
 * @type {{install(*): void}}
 */

export const LProgress = Object.freeze({
	isLoading: function () {
		return store.getters['lprogress/loading']
	},
	begin: function () {
		store.dispatch('lprogress/setLoading', true)
	},
	end: function () {
		store.dispatch('lprogress/setLoading', false)
	}
})

const LProgressPlugin = {
	install(Vue) {
		Vue.prototype.$lprogress = LProgress

        store.registerModule('lprogress', lprogressStore)


	}
}

Vue.use(LProgressPlugin)
