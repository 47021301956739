export default {
    namespaced: true,
    state: {
        loading: false
    },
    getters: {
        loading: state => state.loading
    },
    mutations: {
        SET_LOADING (state, payload) {
            state.loading = payload
        }
    },
    actions: {
        setLoading (context, payload) {
            context.commit('SET_LOADING', payload)
        }
    }
}
