<template>
    <div
        :class="wrapperClasses"
    >
        <label v-if="label" class="w-fields-group__label">{{ label }}</label>
        <div>
            <slot></slot>
        </div>
        <div v-if="!hideDetails" class="pl-2">
            <div class="v-messages error--text" style="height: 14px; margin-bottom: 8px">
                <span>{{ error }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WFieldsGroup',
    props: {
        label: {
            type: String,
            default: undefined
        },
        error: {
            type: String,
            default: undefined
        },
        hideDetails: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        wrapperClasses() {
            const classes = [
                'w-fields-group',
                this.outlined ? 'px-3 w-fields-group--outlined' : null,
                'pt-3',
                !this.hideDetails ? 'pb-3' : null
            ]

            return classes
        }
    }
}
</script>

<style lang=scss>
    .w-fields-group {
        position: relative;

        &--outlined {
            border: solid 1px rgb(220 218 222);
            border-radius: 4px;

            .w-fields-group__label {
                left: 8px;
            }
        }

        &__label {
            position: absolute;
            left: 4px;
            top: -10px;
            background-color: white;
            padding: 0 4px;
            font-size: 13px;
        }
    }
</style>
