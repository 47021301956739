export default {
    props: {
        value: {
            type: String,
            default: undefined
        },
        name: {
            type: String,
            default: undefined
        },
        outlined: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        error: {
            type: Boolean,
            default: false
        },
        errorMessages: {
            type: Array,
            default: function () {
                return []
            }
        },
        required: {
            type: Boolean,
            default: false
        },
        locale: {
            type: String,
            default: 'en'
        },
        hideDetails: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showMessage: false
        }
    },
    methods: {
        validate() {
            return true
        },
        onBlur() {
            this.showMessage = !this.validate()
        }
    }
}
